<template>
  <page title="产品详情" :headerTransparent="!showHeadBg" @on-scroll="onScroll" :show-dialog="showGoodsInfo" @mask-click="showGoodsInfo = false">
    <div class="pt-9/16 relative overflow-hidden  bg-gray-900">
      <div class="absolute inset-0">
        <cube-slide ref="slide" :data="TestData.slides"  class="h-full">
          <cube-slide-item v-for="(item, index) in TestData.slides" :key="index" @click.native="clickHandler(item, index)">
            <img :src="item.image" class="object-cover w-full"/>
          </cube-slide-item>
        </cube-slide>
      </div>
    </div>
    <div class="bg-white">
      <div class="px-4 py-3 flex">
        <div class="flex-1">
          <div class="font-medium truncate">产品标题</div>
          <div class="mt-2 text-gray-light text-xs">
            <span>品牌 : 红苹果</span>
            <span class="ml-4">月销量 : 3002</span>
          </div>
          <div class="mt-2 flex flex-wrap">
            <tag color="red" class="mr-2">1亿像素</tag>
            <tag color="purple" class="mr-2">双扬声器</tag>
            <tag color="green">线性马达</tag>
          </div>
        </div>
        <div class=" text-red-500">
          <span class="text-sm">¥</span>
          <span class="text-lg font-medium">4039</span>
        </div>
      </div>
    </div>
    <title-bar :tab-list="['产品简介','相关产品']" @tab-change="tabChange" class="mt-3"></title-bar>
    <divider class="px-4 bg-white"></divider>
    <div class="px-4 pb-4 bg-white  " v-if="current == 0">
      <div v-html="TestData.postHtml" class="text-sm leading-7 text-gray-700"></div>
    </div>
    <div v-if="current == 1">
      <list :empty="!TestData.videoList">
        <media-item 
          url="/" 
          v-for="item in TestData.videoList" 
          :key="item.id" 
          :image="item.image" 
          :title="item.title"  
          isVideo
        >
          <div class="text-xs text-gray-light leading-3 mt-1" slot="footer">
            直播时间：10-30 14:00
          </div>
        </media-item>
        
      </list>
    </div>
    <cart-bar count="24" slot="footer" @on-cart="onCart" @on-buy="onBuy"></cart-bar>
    <!-- 弹出层 -->
    <div slot="dialog" >
      <div class="flex items-end p-4 relative hairline-bottom">
        <media width="80" ratio="1/1" class="mr-4" :src="TestData.videoList[0].image"></media>
        <div class="flex-1">
          <div class="text-xl font-medium text-red-500">¥ 88.00</div>
          <div class="text-sm mt-2 text-gray-light">请选择颜色、型号、数量</div>
        </div>
        <i class="iconfont icon-close text-xl self-start text-gray-500" @click="showGoodsInfo=false"></i>
      </div>
      <div class="p-4">
        <div class="font-medium py-3">颜色</div>
        <cube-checker v-model="colorSelected" :options="colorOptions" type="radio" />
        <div class="font-medium py-3">规格</div>
        <cube-checker v-model="specSelected" :options="specOptions" type="radio" />
        <div class="flex justify-between items-center">
          <div class="font-medium py-3">数量</div>
          <div class="flex items-center">
            <div class="iconfont icon-sami-select text-xl" @click="onCountChange('minus')"></div>
            <div class="h-6 w-10 rounded leading-6 text-sm bg-gray-200 text-center mx-2">{{count}}</div>
            <div class="iconfont icon-add-select text-xl" @click="onCountChange('plus')"></div>
          </div>
        </div>
      </div>
      <btn radius="none" text-size="base" block @click="submit">确定购买</btn>
    </div>
  </page>
</template>

<script>
import {mapGetters} from 'vuex'
import { CartBar } from '@/components'
export default {
  components: {
    CartBar
  },
  data() {
    return {
      current: 0,
      showGoodsInfo: false,
      colorSelected: [],
      colorOptions: [],
      specSelected: [],
      specOptions: [],
      count: 0,
      showHeadBg: false,
    }
  },
  computed: {
    ...mapGetters([
      'TestData'
    ])
  },
  methods: {
    tabChange(cur,index){
      this.current = index
    },
    clickHandler(item, index) {
      console.log(item);
    },
    onCart() {
      console.log('加入购物车');
      this.showGoodsInfo = true
    },
    onBuy() {
      console.log('购买');
      this.showGoodsInfo = true
    },
    onScroll(e) {
      if (e.y< -10) {
        this.showHeadBg = true
      }else{
        this.showHeadBg = false
      }
    },
    showAlert() {
      this.$createDialog({
        type: 'alert',
        title: '提示',
        content: '已经提交申请',
      }).show()
    },
    submit() {
      this.showAlert();
      this.showGoodsInfo = false
    },
    getGoodsInfo() {
      this.colorOptions = [
        {
          value: 1,
          text: '烟波蓝'
        },
        {
          value: 2,
          text: '静默星空'
        },
        {
          value: 3,
          text: '碧海星辰'
        },
        {
          value: 4,
          text: '湖光秋色'
        }
      ]
      this.specOptions = [
        {
          value: 1,
          text: '全网通128G + 6G'
        },
        {
          value: 2,
          text: '全网通8G+128G'
        },
        {
          value: 3,
          text: '全网通16G+128G'
        },
        {
          value: 4,
          text: '全网通32G+128G'
        }
      ]
    },
    onCountChange(type) {
      if (type == 'minus') {
        this.count --
      }else if (type == 'plus') {
        this.count ++
      }
      if (this.count < 0) this.count = 0
    }
  },
  created() {
    console.log(this.$route);
    this.getGoodsInfo()
  }
}
</script>

<style lang="stylus">

</style>